var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"12b0f9e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENV = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT
const DENY_URLS = ['/healthz']

console.log(`Sentry Server Config > Sentry Env: ${SENTRY_ENV}`)

Sentry.init({
  dsn: SENTRY_DSN,
  denyUrls: DENY_URLS,
  environment: SENTRY_ENV,
  tracesSampler: (samplingContext) => {
    const requestUrl = (samplingContext.location || {}).pathname || null
    const shouldDenyRequest = requestUrl && DENY_URLS.some((denyUrl) => requestUrl === denyUrl)
    if (shouldDenyRequest) {
      return 0
    }
    const userAgent = navigator?.userAgent || ''
    if (userAgent.startsWith('GoogleHC/')) {
      // Filtering out Google Heath Check calls from performance measuring, since these provide
      // no value and are very frequent -- eating at our transaction quota in Sentry for no value.
      return 0
    }
    return 0.1
  },
})
