import '../css/styles.css'
import '../css/tailwind.css'
import '@adyen/adyen-web/dist/adyen.css'
import '../css/adyen-custom.css'
import '@fortawesome/fontawesome-svg-core/styles.css'

// Adding this so fontawsome icon style properly
// For documentation https://fontawesome.com/v5/docs/web/use-with/react#getting-font-awesome-css-to-work
import { config } from '@fortawesome/fontawesome-svg-core'
import * as Sentry from '@sentry/nextjs'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import { makeLocale } from '../components/helpers'
import { clearEmptyStringTranslationCookie } from '../components/language-selector/utils'
import { PageTransition } from '../components/page-wrappers/page-transition'
import { SearchContext } from '../hooks/useSearchContext'
import polyfillIntlForLocale from '../utils/intl-polyfills'
import { ExtendedCommonPageProps } from '../utils/page-types'

config.autoAddCss = false

function App({ Component, pageProps }: AppProps<ExtendedCommonPageProps>) {
  // NOTE(martin 2024-08-15) When the translation feature was built, selecting 'No translation' would save a cookie with an empty string.
  // The backend expects the value from the cookie to be either a language code or undefined.
  // This function clears the cookie if it's an empty string, to prevent a validation error on the backend.
  // At the time of writing, the lifetime of this cookie is one year which means we can remove this code after 2025-08-15.
  clearEmptyStringTranslationCookie()

  const [searchVisible, setSearchVisible] = useState(false)

  const value = {
    isVisible: searchVisible,
    toggleSearch: () => {
      setSearchVisible(!searchVisible)
    },
  }

  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale)
  const [loadingPolyfill, setLoadingPolyfill] = useState(true)
  useEffect(() => {
    if (loadingPolyfill) {
      polyfillIntlForLocale(locale)
        .then(() => {
          setLoadingPolyfill(false)
        })
        .catch((error) => {
          console.log({ error })
          Sentry.captureException(error, { extra: { source: 'polyfillForLocal', locale } })
        })
    }
  }, [locale, setLoadingPolyfill, loadingPolyfill])
  if (loadingPolyfill) {
    // Note(marcus) Could perhaps show a loading here but it will probably just flash really quickly
    return <div />
  }
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" key="viewport" />
      </Head>
      <SearchContext.Provider value={value}>
        <PageTransition>
          <Component {...pageProps} />
        </PageTransition>
      </SearchContext.Provider>
    </>
  )
}

export default App
