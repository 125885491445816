import nookies from 'nookies'

import { TRANSLATION_COOKIE } from '../../utils/constants'
import { TranslationLanguage } from './types'

export const clearEmptyStringTranslationCookie = () => {
  const translationCookie = nookies.get(null, { path: '/' })[TRANSLATION_COOKIE] ?? undefined
  if (translationCookie === '') {
    nookies.destroy(null, TRANSLATION_COOKIE, { path: '/' })
  }
}

export const destroyTranslationCookie = () => {
  nookies.destroy(null, TRANSLATION_COOKIE, { path: '/' })
}

export const translationLanguages: TranslationLanguage[] = [
  { langCode: 'en', name: 'English' },
  { langCode: 'sv', name: 'Svenska' },
  { langCode: 'no', name: 'Norsk' },
  { langCode: 'fi', name: 'Suomi' },
  { langCode: 'es', name: 'Español' },
  { langCode: 'de', name: 'Deutsch' },
  { langCode: 'fr', name: 'Français' },
  { langCode: 'ar', name: 'اللغة العربية' },
  { langCode: 'pl', name: 'Polski' },
  { langCode: 'ru', name: 'Русский' },
  { langCode: 'zh', name: '中文' },
  { langCode: 'ja', name: '日本語' },
]
