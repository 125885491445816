import { motion } from 'framer-motion'
import { AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import React, { FC } from 'react'

import { PAGE_TRANSITION_DURATION_S } from '../../../utils/constants'
import { xScrollToTarget } from '../../sticky-anchor-nav/x-scroll-to-target'
import { Direction, usePageTransitionDirection } from './hooks'

const getMotionProps = (direction: Direction) => {
  const isDirectionBack = direction === 'BACK'
  const onTheRight = { x: '100%', height: 'auto', minHeight: '100%' }
  const inTheCenter = { x: 0, height: 'auto', minHeight: '100%' }
  const onTheLeft = { x: '-100%', height: 'auto', minHeight: '100%' }

  const initial = isDirectionBack ? onTheLeft : onTheRight
  const exit = isDirectionBack ? onTheRight : onTheLeft
  const animate = inTheCenter

  const transition = {
    duration: PAGE_TRANSITION_DURATION_S,
    ease: 'easeIn',
  }

  return {
    initial,
    exit,
    animate,
    transition,
  }
}

export const PageTransition: FC = ({ children }) => {
  const { asPath } = useRouter()

  const direction = usePageTransitionDirection()
  const motionProps = getMotionProps(direction)

  return (
    <AnimatePresence
      initial={false}
      onExitComplete={() => {
        /*
        note (Kenneth):
        A location hash that matches the id-attribute on the target page will interfere with the transition animation.
        Instead we select the target element by a data-attribute and scroll to it.
        */
        if (typeof window === 'undefined' || !asPath.includes('/menu#') || !location.hash) return

        const targetMenuGroupSection = document.querySelector(
          `[data-menu-section="${location.hash.slice(1)}"]`
        )

        if (targetMenuGroupSection) {
          const targetElementPosition = targetMenuGroupSection.getBoundingClientRect()
          // scroll to the selected menu section
          window.scrollTo({
            top: window.scrollY + targetElementPosition.top - 126, // compensate for sticky-anchor-nav
          })
        }

        const stickyAnchorNavScrollContainer = document.getElementById('sticky-anchor-nav')
        const stickyAnchorTargetMenuButton = document.querySelector(
          `[data-sticky-anchor-nav-is-seleted="true"]`
        ) as HTMLElement

        if (stickyAnchorNavScrollContainer && stickyAnchorTargetMenuButton) {
          xScrollToTarget(stickyAnchorNavScrollContainer, stickyAnchorTargetMenuButton, true)
        }
      }}
      mode="popLayout">
      <motion.div key={asPath} {...motionProps}>
        {children}
      </motion.div>
    </AnimatePresence>
  )
}
