import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export type Direction = 'FORWARD' | 'BACK'

type RoutingOrders = Record<string, number>

const routingOrders: RoutingOrders = {
  qr: 1,
  start: 2,
  menu: 3,
  confirmation: 4,
  tab: 5,
  checkout: 6,
  user: 7,
  order: 8,
  default: 9, // Make sure any non-matched routes always animate in forward
}

const getRoutingOrder = (path: string, locales: string[]) => {
  const localePattern = new RegExp(`\\b(${locales.join('|')})\\b`, 'gi')
  const pathWithoutLocale = path.replace(localePattern, '')
  const pathParts = pathWithoutLocale.split('/').filter((part) => part)
  const cleanBasePath = pathParts.length && pathParts?.[0].split('#')[0].split('?')[0] // Removing hash and query params

  if (!cleanBasePath) {
    return routingOrders.start
  }

  const hasRoutingOrderForBase = Boolean(routingOrders[cleanBasePath])
  if (hasRoutingOrderForBase) {
    return routingOrders[cleanBasePath]
  }
  return routingOrders.default
}

export const usePageTransitionDirection = (): Direction => {
  const router = useRouter()

  const [currentRouterOrder, setCurrentRouterOrder] = useState(() => {
    const initialOrder = getRoutingOrder(router.asPath, router.locales || [])
    return initialOrder
  })
  const [pageTransitionDirection, setPageTransitionDirection] = useState<Direction>('FORWARD')

  useEffect(() => {
    const beforeHistoryChange = (url: string) => {
      const nextOrder = getRoutingOrder(url, router.locales || [])
      if (nextOrder === currentRouterOrder) {
        // Navigation to the same page does not trigger a transition. This can happen on sub pages
        // such as /checkout to /checkout/pending?id=123
        return
      }
      const nextDirection = nextOrder > currentRouterOrder ? 'FORWARD' : 'BACK'
      setPageTransitionDirection(nextDirection)
      setCurrentRouterOrder(nextOrder)
    }

    router.events.on('beforeHistoryChange', beforeHistoryChange)

    return () => {
      router.events.off('beforeHistoryChange', beforeHistoryChange)
    }
  }, [router, currentRouterOrder, setCurrentRouterOrder, setPageTransitionDirection])

  return pageTransitionDirection
}
