import { useRouter } from 'next/router'

export enum Locale {
  sv = 'sv',
  en = 'en',
  no = 'no',
}

export type TranslationSetEntry =
  | string
  | ((args?: any) => TranslationSetEntry) // eslint-disable-line @typescript-eslint/no-explicit-any
  | JSX.Element
  | TranslationSet

type TranslationDict<T extends Record<string, TranslationSetEntry>> = {
  en: T
  sv: { [K in keyof T]: T[K] }
  no: { [K in keyof T]: T[K] }
}

/** Type checking function that simply returns the passed in argument. Use this instead of TranslationMap<> */
export function _T<T extends Record<string, TranslationSetEntry>>(
  translations: TranslationDict<T>
): TranslationDict<T> {
  return translations
}

type TranslationSet = {
  [key: string]: TranslationSetEntry
}

export type TranslationMap<T extends TranslationSet> = {
  [key in Locale]: T
}

export enum Path {
  '/' = '/',
}

const removeRegion = (locale: string): string => {
  const [noRegion] = locale.split('-')
  return noRegion
}

const isValidLocale = (value: string): value is Locale => {
  return Object.values(Locale).includes(value as Locale)
}

const validateLocale = (locale: string, defaultLocale: string): Locale => {
  const validDefault = isValidLocale(defaultLocale) ? defaultLocale : Locale.en
  return isValidLocale(locale) ? locale : validDefault
}

export const makeLocale = (locale = 'en', defaultLocale = 'en'): Locale => {
  if (!locale) {
    return defaultLocale as Locale
  }
  if (locale.includes('-')) {
    const noRegion = removeRegion(locale)
    return validateLocale(noRegion, defaultLocale)
  } else {
    return validateLocale(locale, defaultLocale)
  }
}

export const useTranslations = <T extends TranslationSet>(map: TranslationMap<T>): T => {
  const router = useRouter()
  const locale = makeLocale(router.locale, router.defaultLocale)
  return map[locale]
}

export const arrayToReadableList = (array: string[]): string => {
  if (!array) {
    return ''
  }
  const a = array.map((title, i) => {
    const length = array.length
    if (length === 1) {
      return title
    } else if (length === 2) {
      return i === 0 ? `${title}` : ` & ${title}`
    } else {
      if (i === length - 1) {
        return `& ${title}`
      }
      if (i === length - 2) {
        return `${title} `
      }
      return `${title}, `
    }
  })
  return a.join('')
}
