import React, { useContext } from 'react'

interface SearchContextType {
  isVisible: boolean
  toggleSearch: () => void
}

export const SearchContext = React.createContext<SearchContextType>({
  isVisible: false,
  toggleSearch: () => null,
})

export const useSearch = () => {
  const context = useContext(SearchContext)

  return { isVisible: context.isVisible, toggleSearch: context.toggleSearch }
}
