import { shouldPolyfill as shouldPolyfillIntl } from '@formatjs/intl-locale/should-polyfill'
import { shouldPolyfill as shouldPolyfillNumber } from '@formatjs/intl-numberformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillPlural } from '@formatjs/intl-pluralrules/should-polyfill'

export default async function polyfillIntlForLocale(locale: string) {
  if (!locale) {
    return
  }
  if (shouldPolyfillIntl()) {
    await import('@formatjs/intl-locale/polyfill')
  }

  const unsupportedNumberLocale = shouldPolyfillNumber(locale)
  if (unsupportedNumberLocale) {
    await import('@formatjs/intl-numberformat/polyfill-force')
    await import(`@formatjs/intl-numberformat/locale-data/${unsupportedNumberLocale}`)
  }

  const unsupportedPluralLocale = shouldPolyfillPlural(locale)
  if (unsupportedPluralLocale) {
    await import('@formatjs/intl-pluralrules/polyfill-force')
    await import(`@formatjs/intl-pluralrules/locale-data/${unsupportedPluralLocale}`)
  }
}
