/* note (kenneth):
  This helper is used to scroll the slider to the selected button in StickyAnchorNav
*/
export const xScrollToTarget = (
  container: HTMLElement,
  target: HTMLElement,
  isCombinedWithPageTransition = false
) => {
  const targetElementPosition = target.getBoundingClientRect()
  const targetElementWidth = target.offsetWidth

  let windowWidth = window.innerWidth

  // When combined with page transition, the window width is doubled
  if (isCombinedWithPageTransition) {
    windowWidth = windowWidth / 2
  }

  const differenceWidth = windowWidth - targetElementWidth
  // compensate the position of the selected button to be centered in the scroll container.
  // or at least 34px from the left side if the button is wider than the scroll container, letting the user see the edge of the previous button.
  const compensatePosition = Math.max(differenceWidth / 2, 34)

  container.scrollTo({
    left: container.scrollLeft + targetElementPosition.left - compensatePosition,
    behavior: 'smooth',
  })
}
